import { useDispatch, useSelector } from 'react-redux'
import {
  selectCustomerSubscriptionData,
  selectElectricityMeterSubscriptionData,
  selectMainAddressSubscriptionData,
} from '../../../../SubscriptionFormSelectors'
import { subscriptionFormActions } from '../../../../SubscriptionFormSagas'
import { useEffect, useState, useRef } from 'react'
import { addCommonFormErrors, updateCustomerModuleConfiguration } from '../../../../SubscriptionFormConfigurationSlice'
import { useTranslation } from 'react-i18next'
import {
  selectCommonFormConfigurationData,
  selectCustomerModuleConfigurationData,
} from '../../../../SubscriptionFormConfigurationSelectors'
import useFormStatus from '../../../../useFormStatus'
import getError from '../../../../getError'
import { selectAuthData } from '@/containers/Login/AuthSelectors'

const useNewsletterField = disabled => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [isDisabled, setIsDisabled] = useState(disabled)
  const [errorMessage, setErrorMessage] = useState('')
  const { lang } = useSelector(selectAuthData)
  const { zohoId, name, surname, phone1, email } = useSelector(selectCustomerSubscriptionData)
  const emailRef = useRef(email);
  const { pdlNo, enedisAuth } = useSelector(selectElectricityMeterSubscriptionData)
  const { city, streetNumber, address, zipcode } = useSelector(selectMainAddressSubscriptionData)
  const { newsletterData, newsletterLoading } = useSelector(selectCustomerModuleConfigurationData)
  const { errors } = useFormStatus()
  const emailHasError = getError('email', errors)
  const { id } = useSelector(selectCommonFormConfigurationData)
  const additionalRequiredDataNewsletter = [
    {
      name: t('additionalSales:list_data.email'),
      status: email?.trim().length > 0,
    },
    {
      name: t('additionalSales:list_data.firstname'),
      status: name?.length > 0,
    },
  ]
  const additionalRequiredDataMySelectra = [
    {
      name: 'PDL',
      status: enedisAuth === true,
    },
    {
      name: t('additionalSales:list_data.address'),
      status: city !== '' && zipcode !== '' && address !== '',
    },
  ]

  const triggerNewsletterRegistrationCallback = (responseData, responseStatus) => {
    console.log('triggerNewsletterRegistrationCallback', responseData, responseStatus)
    if (responseStatus === 200) {
      return
    }
    dispatch(updateCustomerModuleConfiguration({newsletterLoading: false}))
    setErrorMessage(t('subscriptionForm:customer_module.newsletter.request_error') + ' : ' + responseData)
  }

  const sendNewsletterCallback = () => {
    const options = {
      zoho_id: zohoId,
      lastname: surname ?? '',
      firstname: name ?? '',
      email: email ?? '',
      phone1: phone1 ?? '',
      city: city ?? '',
      meter_id_elec: pdlNo ?? '',
      street_number: streetNumber ?? '',
      street_name: address ?? '',
      zipcode: zipcode ?? '',
    }
    dispatch(updateCustomerModuleConfiguration({ newsletterLoading: true, newsletterData: {} }))
    setErrorMessage('')
    dispatch({
      type: subscriptionFormActions.CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER,
      options,
      callback: triggerNewsletterRegistrationCallback,
    })
  }

  useEffect(() => {
    const isFormIncompleteToSendNewsletter = !zohoId || !name || !surname || !phone1 || !email;
    setIsDisabled(disabled || isFormIncompleteToSendNewsletter);
  }, [zohoId, name, surname, phone1, email, disabled]);

  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  useEffect(() => {
    if (!id) {
      return
    }

    window.Echo.channel(`register.newsletter.${id}`).listen('.newsletter.registered.response', e => {
      console.log('RegisterNewsletterResponse', e, e.email, e.email !== emailRef.current, emailRef.current)
      if (e.email !== emailRef.current) {
        return
      }
      dispatch(updateCustomerModuleConfiguration({ newsletterLoading: false }))

      if (e.status === 'error') {
        setErrorMessage(t('subscriptionForm:customer_module.newsletter.request_error') + ' : ' + e.response.exception)
        dispatch(addCommonFormErrors({ newsletter: [e.response.exception] }))
      } else {
        setIsDisabled(true)
        dispatch(updateCustomerModuleConfiguration({ newsletterData: { ...e.response, status: e.status } }))
      }
    })
    return () => {
      window.Echo.leaveChannel(`register.newsletter.${id}`)
    }
  }, [id])

  return {
    emailHasError,
    sendNewsletter: sendNewsletterCallback,
    email,
    pdlNo,
    isDisabled,
    errorMessage,
    t,
    newsletterLoading,
    newsletterData,
    lang,
    additionalRequiredDataNewsletter,
    additionalRequiredDataMySelectra,
    zohoId,
    surname,
    phone1,
  }
}

export default useNewsletterField
