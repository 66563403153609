import PanelNew from '@/containers/SubscriptionFormPageNew/Panel/PanelNew'
import { FormControl } from 'baseui/form-control'
import { SIZE } from 'baseui/input'
import logo from './zoho-logo.svg'
import SDInput from '@/shared/components/SDComponents/SDInput/SDInput'
import ZohoUpdater from './ZohoUpdater'
import ZohoMcpUnlocker from './ZohoMcpUnlocker'
import { Tag, VARIANT } from 'baseui/tag'
import { useSelector } from 'react-redux'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import useZoho from './useZoho'
import HOPNotification from '@/shared/components/HOPComponents/HOPNotification'
import { modulesConfig } from '../../config'

const ZohoId = () => {
  const {
    formIsLoaded,
    LoadingPanelNew,
    errorText,
    mandatory,
    onZohoIdBlurHandler,
    customerZohoIdUpdate,
    isEditable,
    zohoId,
    zohoDossierUrl,
    t,
    isMcpClient,
  } = useZoho()

  const { country } = useSelector(selectAuthData)
  const config = modulesConfig[country].zoho ?? {}
  const zohoLink = zohoDossierUrl ? (
    <a href={zohoDossierUrl} target='_blank' rel='noopener noreferrer'>
      <img src={logo} alt='Zoho Logo' width='20' height='20' />
    </a>
  ) : null

  if (!formIsLoaded) {
    return LoadingPanelNew('ZohoModule', t('subscriptionForm:zoho_module.module_title'), 'ZohoModule', logo)
  }

  return (
    <PanelNew
      alt={t('subscriptionForm:zoho_module.module_title')}
      logo={logo}
      title={t('subscriptionForm:zoho_module.module_title')}
      id='ZohoModule'
    >
      <FormControl
        label={
          <span>
            {t('subscriptionForm:zoho_module.module_title')}
            {mandatory}
            {zohoLink}
          </span>
        }
        error={errorText}
      >
        <SDInput
          id='zoho-module-input'
          disabled={isEditable === false}
          size={SIZE.compact}
          placeholder={t('subscriptionForm:zoho_module.module_title')}
          value={zohoId ?? ''}
          onChange={event => customerZohoIdUpdate(event.target.value)}
          onBlur={onZohoIdBlurHandler}
          error={errorText !== ''}
          autoComplete={'off'}
        />
      </FormControl>

      {!zohoId && (
        <HOPNotification primaryText={t('subscriptionForm:zoho_module.warning_zoho_missing')} type='warning' />
      )}
      {<ZohoUpdater />}

      {config.mcp && isMcpClient && (
        <Tag closeable={false} variant={VARIANT.solid} kind='warning'>
          {t('subscriptionForm:zoho_module.tag_mcp_text')}
        </Tag>
      )}

      {config.mcp && <ZohoMcpUnlocker />}
    </PanelNew>
  )
}

export default ZohoId
