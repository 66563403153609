import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import useBusinessType from '@/shared/hooks/useBusinessType'
import { selectAuthData } from '@/containers/Login/AuthSelectors'
import { pageConfigurationActions } from '@/store/PageConfiguration/PageConfigurationSagas'
import { userManagementActions } from './UsersManagementSagas'
import {
  selectPageConfigurationData,
  selectPageConfigurationIsLoading,
} from '@/store/PageConfiguration/PageConfigurationSelectors'
import { selectUsersManagement } from './UsersManagementSelectors'
import { useTranslation } from 'react-i18next'
import useModal from '@/shared/hooks/useModal'
import { updateUsersManagementSelectedUser } from './UsersManagementSlice'

const useUsersManagement = () => {
  const dispatch = useDispatch()
  const { loaderRoutesEnergy, loaderRoutes, loaderRoutesInsurance, lang } = useSelector(selectAuthData)
  const { getApplicationUrl } = useBusinessType({
    loaderRoutesEnergy,
    loaderRoutes,
    loaderRoutesInsurance,
    modulesStatuses: {},
    modulesStatusesEnergy: {},
    modulesStatusesInsurance: {},
  })
  const { users, selectedUser, isLoadingUsers, usersError } = useSelector(selectUsersManagement)

  const initUrl = getApplicationUrl('users-mngt')
  const { routes } = useSelector(selectPageConfigurationData)
  const isLoading = useSelector(selectPageConfigurationIsLoading)

  const { t } = useTranslation()
  const { isOpen, close, setIsOpen } = useModal()

  const [page, setPage] = useState(1)
  const [searchValue, setSearchValue] = useState('')

  const updateUser = user => {
    const sitesSelected = user?.authorized_sites?.map(site => site.site_id)

    const userSelected = {
      id: user?.id ?? 0,
      name: user?.id ? user.name : '',
      firstname: user?.id ? user.firstname : '',
      email: user?.id ? user.email : '',
      zoho_id: user?.id ? user.zoho_id : '',
      tde_id: user?.id ? user.tde_id : '',
      hangouts_space_name: user?.id ? user.hangouts_space_name : '',
      role: user?.id ? user.role : '',
      language: user?.language ? user.language : '',
      authorized_sites: user?.id ? sitesSelected : [],
      organisation_id: user?.id ? user.organisation_id : '',
      ip_address_whitelist_id: user?.id ? user.ip_address_whitelist_id : '',
      is_cousin_hub: user?.id ? user.is_cousin_hub : false,
      calendly_url: user?.id ? user.calendly_url : '',
      office_uuid: user?.id ? user.office_uuid : '',
    }
    dispatch(updateUsersManagementSelectedUser(userSelected))

    setIsOpen(true)
  }

  const changeUserStatus = (status, userId, isUserActive) => {
    dispatch({ type: userManagementActions.UPDATE_USER_STATUS, status, userId, isUserActive })
  }

  const getLoadUserUrl = () => {
    const list = routes.list ?? ''
    return `${list}?page=${page}&search=${encodeURIComponent(searchValue)}&lang=${lang}`
  }

  useEffect(() => {
    dispatch({ type: pageConfigurationActions.INITIALIZE, initUrl })
  }, [])

  useEffect(() => {
    if (!routes?.list) return
    dispatch({ type: userManagementActions.LOAD_LIST, url: getLoadUserUrl() })
  }, [page, searchValue, routes])

  return {
    users,
    setSearchValue,
    setPage,
    usersListIsLoading: isLoadingUsers,
    pageConfigurationIsLoading: isLoading,
    t,
    isOpen,
    close,
    updateUser,
    changeUserStatus,
    selectedUser,
    setIsOpen,
    usersError,
  }
}

export default useUsersManagement
