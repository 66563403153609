import { takeLatest } from 'redux-saga/effects'
import {
  customerModuleActions,
  registerToNewsletter,
  verifyEmail,
  verifySiret,
} from '../Components/Customer/CustomerModuleSagas'
import { unlockMcpClient, updateZohoId, verifyZohoId, zohoModuleActions } from '../Components/ZohoId/ZohoModuleSagas'
/* import {
  characteristicsModuleActions,
  searchAddress,
  cityEligibilityCheck,
} from './ModulesPanel/Modules/CharacteristicsModule/CharacteristicsModuleSagas'
import {
  propaneModuleActions,
  loadPropaneEnergySuppliers,
  loadPropaneQuoteReasons,
  loadPropaneVisibility,
} from './ModulesPanel/Modules/PropaneModule/PropaneModuleSagas'
import {
  consumptionModuleActions,
  calculateOfferPrice,
} from './ModulesPanel/Modules/ConsumptionModule/ConsumptionModuleSagas'
import {
  offerModuleActions,
  loadProviders,
  loadOffers,
  loadProviderOptions,
  loadOfferInformation,
  loadProviderInformation,
  loadEnergyOrigin,
} from './ModulesPanel/Modules/OfferModule/OfferModuleSagas'
import {
  billingModuleActions,
  fetchPromoCode,
} from './ModulesPanel/Modules/BillingPaymentModule/BillingPaymentModuleSagas'
import {
  pceModuleActions,
  checkDuplicatedMeter,
  requestMeterData,
  validateMeterSyntax,
} from './ModulesPanel/Modules/PceModule/PceModuleSagas'
import {
  pdlModuleActions,
  requestPdlMeterData,
  validatePdlMeterSyntax,
  checkDuplicatedPdlMeter,
  retrievePdlCacheDataForKey,
} from './ModulesPanel/Modules/PdlModule/PdlModuleSagas'
import {
  carEstimatorToolActions,
  carEstimatorRetrieveEstimation,
} from './ToolsPanel/Modules/CarEstimationTool/CarEstimatorToolSagas'
*/
export const subscriptionFormActionsV2 = {
  ...zohoModuleActions,
  ...customerModuleActions,
  /* ...characteristicsModuleActions,
  ...propaneModuleActions,
  ...offerModuleActions,
  ...billingModuleActions,
  ...pceModuleActions,
  ...pdlModuleActions,
  ...consumptionModuleActions,
  ...carEstimatorToolActions, */
}

export default function* subscriptionFormV2Sagas() {
  yield takeLatest(subscriptionFormActionsV2.ZOHO_MODULE_VERIFY_ID_V2, verifyZohoId)
  yield takeLatest(subscriptionFormActionsV2.ZOHO_MODULE_UPDATE_ID_V2, updateZohoId)
  yield takeLatest(subscriptionFormActionsV2.CUSTOMER_MODULE_VERIFY_EMAIL_V2, verifyEmail)
  yield takeLatest(subscriptionFormActionsV2.CUSTOMER_MODULE_VERIFY_SIRET_V2, verifySiret)
  yield takeLatest(subscriptionFormActionsV2.CUSTOMER_MODULE_REGISTER_TO_NEWSLETTER_V2, registerToNewsletter)
  yield takeLatest(subscriptionFormActionsV2.ZOHO_MODULE_UNLOCK_MCP_CLIENT_V2, unlockMcpClient)
  /* yield takeLatest(subscriptionFormActions.RETRIEVE_PDL_CACHE_DATA, retrievePdlCacheDataForKey)
  yield takeLatest(subscriptionFormActions.SEARCH_ADDRESS, searchAddress)
  yield takeLatest(subscriptionFormActions.CITY_ELIGIBILITY_CHECK, cityEligibilityCheck)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_ENERGY_SUPPLIERS, loadPropaneEnergySuppliers)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDERS, loadProviders)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_OFFERS, loadOffers)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDERS_OPTIONS, loadProviderOptions)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_OFFER_INFORMATION, loadOfferInformation)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_PROVIDER_INFORMATION, loadProviderInformation)
  yield takeLatest(subscriptionFormActions.OFFER_MODULE_LOAD_ENERGY_ORIGIN, loadEnergyOrigin)
  yield takeLatest(subscriptionFormActions.BILLING_MODULE_FETCH_PROMO_CODE, fetchPromoCode)
  yield takeLatest(subscriptionFormActions.METER_MODULE_CHECK_DUPLICATED, checkDuplicatedMeter)
  yield takeLatest(subscriptionFormActions.METER_MODULE_CHECK_DUPLICATED_PDL, checkDuplicatedPdlMeter)
  yield takeLatest(subscriptionFormActions.METER_MODULE_REQUEST_DATA, requestMeterData)
  yield takeLatest(subscriptionFormActions.METER_MODULE_REQUEST_PDL_DATA, requestPdlMeterData)
  yield takeLatest(subscriptionFormActions.METER_MODULE_VALIDATE_METER_SYNTAX, validateMeterSyntax)
  yield takeLatest(subscriptionFormActions.METER_MODULE_VALIDATE_PDL_METER_SYNTAX, validatePdlMeterSyntax)
  yield takeLatest(subscriptionFormActions.CONSUMPTION_MODULE_CALCULATE_PRICE, calculateOfferPrice)
  yield takeLatest(subscriptionFormActions.CAR_ESTIMATOR_TOOL_ESTIMATE, carEstimatorRetrieveEstimation)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_QUOTE_REASONS, loadPropaneQuoteReasons)
  yield takeLatest(subscriptionFormActions.PROPANE_LOAD_VISIBILITY, loadPropaneVisibility) */
}
