import PropTypes from 'prop-types'
import { useStyletron, styled } from 'baseui'
import { Spinner } from 'baseui/spinner'
import { FormControl } from 'baseui/form-control'
import SDButton from '@/shared/components/SDComponents/SDButton/SDButton'
import useNewsletterField from './useNewsletterField'
import PanelInfoBlock from '@/shared/components/Panel/bones/PanelInfoBlock'
import ContractDataRequired from '../../../../AdditionalSalesPanel/bones/ContractDataRequired'

const NewsletterBonsPlans = ({ isNewsletterRegistered, disabled }) => {
  const [css] = useStyletron()
  const Label = styled('div', ({ $theme }) => ({
    ...$theme.typography.LabelMedium,
    color: $theme.colors.contentPrimary,
    marginBottom: $theme.sizing.scale200,
  }))
  const {
    emailHasError,
    sendNewsletter,
    email,
    pdlNo,
    isDisabled,
    errorMessage,
    t,
    newsletterLoading,
    newsletterData,
    lang,
    additionalRequiredDataNewsletter,
    additionalRequiredDataMySelectra,
    zohoId,
    surname,
    phone1,
  } = useNewsletterField(disabled)

  const status = newsletterData?.status || '';

  return (
    <>
      {lang === 'fr' && !pdlNo && !isNewsletterRegistered && (
        <PanelInfoBlock type={'warning'}>
          {t('subscriptionForm:customer_module.newsletter.not_personalized_warning')}
        </PanelInfoBlock>
      )}
      <Label>
        {pdlNo && lang === 'fr'
          ? t('subscriptionForm:customer_module.newsletter.label_personalized')
          : t('subscriptionForm:customer_module.newsletter.label')}
      </Label>
      <ContractDataRequired
        currentData={{ zohoId, surname, phone1 }}
        additionalData={additionalRequiredDataNewsletter} 
        customTitle={t('subscriptionForm:customer_module.newsletter.title_mandatory_fields_newsletter')} 
      />
      <ContractDataRequired
        currentData={{}}
        additionalData={additionalRequiredDataMySelectra} 
        customTitle={t('subscriptionForm:customer_module.newsletter.title_mandatory_fields_myselectra')}
        onlyAdditionalData={true}
      />
      
      <FormControl
        error={errorMessage}
      >
        <SDButton
          block
          isLoading={newsletterLoading}
          disabled={isDisabled || emailHasError}
          onClick={sendNewsletter}
          overrides={{
            BaseButton: {
              style: () => ({
                backgroundColor: 'orange',
                ':hover': {
                  backgroundColor: 'darkorange',
                },
              }),
            },
          }}
        >
          {(() => {
            if (!isDisabled) {
              return t('subscriptionForm:customer_module.newsletter.button');
            }

            if (status === 'success') {
              return t('subscriptionForm:customer_module.newsletter.button_submitted') + '(' + email + ')';
            }

            if (status === 'already_subscribed') {
              return t('subscriptionForm:customer_module.newsletter.button_submitted_already_subscribed');
            }

            if (status === 'already_sent_recently') {
              return t('subscriptionForm:customer_module.newsletter.button_submitted_recently');
            }

            return t('subscriptionForm:customer_module.newsletter.button');
          })()}
        </SDButton>
      </FormControl>

      {newsletterLoading && (
        <div
          className={css({
            alignItems: 'center',
            display: 'flex',
          })}
        >
          <Spinner size='32px' />
          <p
            className={css({
              marginLeft: '10px',
            })}
          >
            {t('subscriptionForm:customer_module.newsletter.loading_info')}
          </p>
        </div>
      )}

      {
        (status === 'error') && (
        <PanelInfoBlock type={'negative'}>
          {newsletterData?.response?.message}
        </PanelInfoBlock>
      )
      }

      {
        (status === 'already_subscribed' ||  status === 'already_sent_recently')  && (
        <PanelInfoBlock type={'warning'}>
          {newsletterData?.response?.message}
        </PanelInfoBlock>
      )
      }

      {
        status === 'success' && (
        <PanelInfoBlock type={'positive'}>
          {newsletterData.response?.message}
        </PanelInfoBlock>
      )
      }

      {
        emailHasError.length > 0 && (
        <PanelInfoBlock type={'negative'}>
          {t('subscriptionForm:customer_module.email.error')}
        </PanelInfoBlock>
      )
      }
    </>
  )
}

NewsletterBonsPlans.propTypes = {
  isNewsletterRegistered: PropTypes.bool,
  disabled: PropTypes.bool,
}

export default NewsletterBonsPlans
