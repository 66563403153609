const additionalSalesDefaultState = {
  leadAssistanceOptions: {
    assistanceId: '',
    zohoId: '',
    customerAcceptation: '',
    axaEmailAlreadySent: false,
    delayActivation: '',
  },
  leadCarbonOffset: {
    offsetId: '',
    zohoId: '',
    customerAcceptation: '',
    electronicSignatureAcceptation: '',
  },
  leadConnectedDevices: {
    heaterCount: '',
    callbackDate: '',
    callbackDetail: '',
    zohoId: '',
    isMainResidence: '',
    isHeatedElectricity: '',
    isMoreThan1Radiator: '',
    constructionYear: '',
    customerCallbackAgreement: '',
    customerInstallationAgreement: '',
    errorPartnerSync: '',
  },
  leadActivationMySelectra: {
    zohoId: '',
    productId: '',
  },
  leadGaranka: {
    customerAgreement: '',
    offerId: '',
    buildingType: '',
    buildingAge: '',
    boilerDisposition: '',
    boilerTechnology: '',
    boilerAge: '',
    boilerBrand: '',
    zohoId: '',
    leadType: '',
  },
  leadEngieHomeService: {
    offer: '',
    boilerAge: '',
    customerAcceptation: '',
    zohoId: '',
    leadType: '',
    errorPartnerSync: '',
  },
  leadMyChauffage: {
    isOwner: '',
    installationType: '',
    installationConditionValidated: '',
    firstCustomerAgreement: '',
    secondCustomerAgreement: '',
    boilerReason: '',
    zohoId: '',
    leadType: '',
    errorPartnerSync: '',
  },
  leadTelecom: {
    type: '',
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: '',
  },
  leadSurveillanceCamera: {
    type: '',
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: '',
  },
  leadAssurance: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    professionalCategory: '',
    description: '',
    leadType: '',
  },
  leadNextories: {
    offerId: '',
    departureCountry: '',
    departureCity: '',
    departureZipcode: '',
    movingDate: '',
    movingDescription: '',
    zohoId: '',
    errorPartnerSync: '',
  },
  leadYouStock: {
    zohoId: '',
  },
  leadMuterLoger: {
    zohoId: '',
    departureCity: '',
  },
  leadTransferMuterLoger: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    description: '',
    moreThan10Employees: null,
  },
  leadBank: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
  },
  leadEnergyPro: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    commitmentEndDate: '',
    description: '',
  },
  leadEnergyProPlaceDesEnergies: {
    zohoId: '',
    commitmentEndDate: '',
    errorPartnerSync: '',
  },
  leadGasBottle: {
    zohoId: '',
  },
  leadFloraAssuranceEthias: {
    zohoId: '',
    leadType: '',
  },
  leadFloraAssuranceLead: {
    zohoId: '',
    leadType: '',
  },
  leadYagoAssuranceHouse: {
    zohoId: '',
    leadType: '',
  },
  leadYagoAssuranceCar: {
    zohoId: '',
    leadType: '',
  },
  leadYagoAssuranceMoto: {
    zohoId: '',
    leadType: '',
  },
  leadSantevetAssurance: {
    zohoId: '',
    leadType: '',
  },
  leadEthias: {
    zohoId: '',
  },
  leadElite: {
    zohoId: '',
    leadType: '',
  },
  leadEngieBrussels: {
    zohoId: '',
    leadType: '',
  },
  leadVerisureTelecomBe: {
    zohoId: '',
    leadType: '',
    orderNumber: '',
  },
  leadBoilerEneco: {
    zohoId: '',
    leadType: '',
  },
  leadBoilerEngie: {
    zohoId: '',
    leadType: '',
  },
  leadBoilerHomeserve: {
    zohoId: '',
    leadType: '',
  },
  leadPartnership: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    description: '',
  },
  leadProxiserve: {
    zohoId: '',
    leadType: '',
  },
  leadWater: {
    zohoId: '',
  },
  leadMesAllocs: {
    zohoId: '',
  },
  leadGroupPurchase: {
    zohoId: '',
  },
  leadGroupPurchaseTelecom: {
    zohoId: '',
  },
  leadAssistancePro: {
    zohoId: '',
  },
  leadEdfInteraction: {
    zohoId: '',
  },
  leadEnergyRenovation: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
    motivation: '',
    description: '',
  },
  leadProxiserveRadiator: {
    zohoId: '',
  },
  leadProxiserveWaterHeater: {
    zohoId: '',
  },
  leadMonConseillerPerso: {
    zohoId: '',
    offer: '',
    partnerId: '',
    errorPartnerSync: '',
    statusCodePending: true,
  },
  leadAssuranceEmprunteur: {
    zohoId: '',
    leadType: '',
    isEligible: '',
  },
  leadBankSG: {
    zohoId: '',
  },
  leadBankPanorabanques: {
    zohoId: '',
  },
  leadIsolation: {
    zohoId: '',
  },
  leadChargingPoint: {
    zohoId: '',
    chargingStationProvider: '',
  },
  leadEkwateurSolarKit: {
    zohoId: '',
  },
  leadSolarPanelsOtovoTE: {
    zohoId: '',
    leadType: '',
  },
  leadComparateur: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: '',
  },
  leadBankinAssurance: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    type: '',
    description: '',
  },
  leadProxiservePAC: {
    zohoId: '',
  },
  leadVerisure: {
    zohoId: '',
  },
  leadTucoenergie: {
    zohoId: '',
    typeCivility: '',
    typeOccupation: '',
    typeRequest: '',
    leadStatus: '',
    errorPartnerSync: '',
  },
  leadOffshoreEnergie: {
    zohoId: '',
    currentProvider: '',
  },
  leadOffshoreTelecom: {
    zohoId: '',
    currentProvider: '',
  },
  leadAssurancePro: {
    zohoId: '',
    callbackDate: '',
    callbackTime: '',
  },
  leadTelecomComparator: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: '',
  },
  leadTelecomEnergy: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: '',
  },
  leadTelecomFree: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: '',
  },
  leadTelecomSat: {
    callbackDate: '',
    callbackTime: '',
    zohoId: '',
    leadType: '',
    description: '',
  },
  leadAxaAssurance: {
    zohoId: '',
    leadType: '',
    imei: '',
    deviceStatus: '',
    productId: '',
    axaEmailAlreadySent: false,
  },
  leadAssuranceProTelco: {
    zohoId: '',
    productId: '',
    callbackDate: '',
    callbackTime: '',
  },
  leadVerisureTelecom: {
    zohoId: '',
    productId: '',
  },
  leadProtected: {
    zohoId: '',
    productId: '',
    invoiceFrequency: '',
    smsCode: '',
    errorPartnerSync: '',
    statusCode: null,
  },
  leadAccessoriesSfr: {
    zohoId: '',
    product: '',
    productId: '',
    saleId: '',
  },
  leadAxaSmartphone: {
    zohoId: '',
    productId: '',
  },
  leadVoltalisBizdev: {
    zohoId: '',
    customerAgreement: '',
    recapAgreement: '',
  },
  leadCresus: {
    zohoId: '',
  },
  leadHomyos: {
    zohoId: '',
    typeFormCreated: '',
    productId: '',
  },
  leadAssuranceEmprunteurMeilleurTaux: {
    zohoId: '',
    productId: '',
  },
  leadEddyLocataire: {
    zohoId: '',
  },
  leadEddyProprietaire: {
    zohoId: '',
  },
}

export default additionalSalesDefaultState
