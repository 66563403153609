import { call, put, takeLatest } from 'redux-saga/effects'
import { updateAuthData, updateAuthError, updateAuthIsLoading } from './AuthSlice'
import { runtimeConfig } from '@/runtime-config'
import { config } from '@/containers/Login/config'

export const authActions = {
  LOGIN: 'LOGIN',
  LOGIN_WITH_GOOGLE: 'LOGIN_WITH_GOOGLE',
  LOGOUT: 'LOGOUT',
}

const baseUrl = runtimeConfig.APP_URL

const TELECOM = 'telecom'

const LOGIN_ENDPOINT = '/api/spa/telecom/login'
const LOGIN_GOOGLE_ENDPOINT = '/api/spa/telecom/login-google'

function* saveSession({ data, navigate }) {
  const {
    api_token: authTokenSession,
    loaderPaths: loaderRoutes,
    loaderPathsEnergy: loaderRoutesEnergy,
    loaderPathsInsurance: loaderRoutesInsurance,
    id: userId,
    lang,
    userLanguage,
    country,
    modulesStatuses,
    modulesStatusesEnergy,
    modulesStatusesInsurance,
    isSelectraAdmin,
    isMcpAgent,
    isAdmin,
    vonageStatus,
    extraRoutes,
    vonageDialerStatus,
    isExternal,
    authorizedSites,
  } = data

  const authData = {
    authTokenSession,
    loaderRoutes,
    loaderRoutesEnergy,
    loaderRoutesInsurance,
    userId,
    lang,
    country,
    userLanguage,
    modulesStatuses,
    modulesStatusesEnergy,
    modulesStatusesInsurance,
    isSelectraAdmin,
    isMcpAgent,
    isAdmin,
    vonageStatus,
    extraRoutes,
    vonageDialerStatus,
    isExternal,
    authorizedSites,
    sessionExpiration: new Date(new Date().getTime() + config.minutesSessionTime * 60000),
  }

  yield put(updateAuthData({ ...authData, initialized: true }))

  const queryParameters = new URLSearchParams(window.location.search)

  const nextRoute = queryParameters.get('from') || '/'

  navigate(nextRoute)
}

export function* loginSaga({ email, password, country, navigate }) {
  yield put(updateAuthIsLoading(true))

  const formData = new FormData()
  formData.append('email', email)
  formData.append('password', password)
  //Todo change once we update backend lang usage as lang and not country
  formData.append('lang', country)
  formData.append('country', country)
  formData.append('type', TELECOM)

  try {
    const request = yield call(fetch, `${baseUrl}${LOGIN_ENDPOINT}`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'Application/json',
      },
      body: formData,
    })

    const { status } = request
    const { data, errorMessage } = yield call([request, request.json])

    if (status === 200) {
      yield call(saveSession, { data, navigate })
    } else {
      yield put(updateAuthError(errorMessage))
    }
  } catch (error) {
    yield put(updateAuthError(JSON.parse(error)))
  } finally {
    yield put(updateAuthIsLoading(false))
  }
}

export function* loginWithGoogleSaga({ accessToken, navigate, country }) {
  yield put(updateAuthIsLoading(true))

  try {
    const formData = new FormData()
    formData.append('accessToken', accessToken.access_token)
    formData.append('type', TELECOM)
    formData.append('lang', country)
    formData.append('country', country)

    const request = yield call(fetch, `${baseUrl}${LOGIN_GOOGLE_ENDPOINT}`, {
      method: 'post',
      mode: 'cors',
      headers: {
        Accept: 'Application/json',
      },
      body: formData,
    })

    const { status } = request
    const { data, errorMessage } = yield call([request, request.json])

    if (status === 200) {
      yield call(saveSession, { data, navigate })
    } else {
      yield put(updateAuthError(errorMessage))
    }
  } catch (error) {
    yield put(updateAuthError(JSON.parse(error)))
  } finally {
    yield put(updateAuthIsLoading(false))
  }
}

export default function* authSagas() {
  yield takeLatest(authActions.LOGIN, loginSaga)
  yield takeLatest(authActions.LOGIN_WITH_GOOGLE, loginWithGoogleSaga)
}
