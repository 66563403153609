import PanelNew from '@/containers/SubscriptionFormPageNew/Panel/PanelNew'
import logo from './user.svg'
import isMandatory from '@/containers/SubscriptionFormPage/isMandatory'
import getError from '@/containers/SubscriptionFormPage/getError'
import Disclaimer from './Fields/Disclaimer'
import useCustomer from './useCustomer'
import { modulesConfig } from '../../config'
import ProSiret from '../../../SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/ProSiret'
import { FormControl } from 'baseui/form-control'
import { HOPInput } from '@/shared/components/HOPComponents/HOPInput'
import HOPSelect from '@/shared/components/HOPComponents/HOPSelect'
import { ParagraphMedium } from 'baseui/typography'
import { HOPButtonGroup } from '@/shared/components/HOPComponents/HOPButtonGroup'
import optionsToArray from '@/shared/tools/optionsToArray'
import Email from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/Email'
import NewsletterBonsPlans from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/NewsletterBonsPlans'
import SecondName from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/SecondName'
import ReferralContact from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/ReferralContact'
import BacVoucher from '@/containers/SubscriptionFormPage/ModulesPanel/Modules/CustomerModule/Fields/BacVoucher'
import { IS_PROFESSIONAL } from '../../SubscriptionForm/Common/constants'
import ClientType from './Fields/ClientType'

/*
To be check what changes between all countries
import EnergyWithoutDataCollection from './Energy/EnergyWithoutDataCollection'
import TelecomFr from './Telecom/FR/Fields'
import EnergyFr from './Energy/FR/Fields'
import EnergyBe from './Energy/BE/Fields'
import EnergyIt from './Energy/IT/Fields'
import InsuranceFr from './Insurance/FR/Fields'
import TelecomBe from './Telecom/BE/Fields'

const localizedModules = {
  'energy-fr': EnergyFr,
  'energy-be': EnergyBe,
  'energy-it': EnergyIt,
  'insurance-fr': InsuranceFr,
  'telecom-fr': TelecomFr,
  'telecom-be': TelecomBe,
  default: TelecomFr,
} */

const Customer = () => {
  const {
    t,
    customerUpdate,
    authorizeDataCollectionHandler,
    dataCollectionAuth,
    mandatoryFields,
    surname,
    name,
    phone1,
    phone2,
    civility2,
    surname2,
    name2,
    secondName,
    proSiret,
    proSocialReason,
    proApeCode,
    proActivity,
    activityError,
    bacVoucher,
    referralFirstName,
    referralSecondName,
    activitiesList,
    isEditable,
    lang,
    errors,
    formIsLoaded,
    LoadingPanelNew,
    verifySiret,
    civility,
    civilityList,
    removeErrors,
    siretIsLoading,
    siretError,
    prefilledEmail,
    captionForceEmail,
    email,
    emailError,
    emailIsLoading,
    verifyEmail,
    isNewsletterRegistered,
    taxIdCode,
    zohoIsInValid,
    clientTypeOptions,
    clientType,
  } = useCustomer()

  const config = modulesConfig[lang].customer ?? {}

  if (!formIsLoaded) {
    return LoadingPanelNew(
      'CustomerModule',
      t('subscriptionForm:customer_module.module_title_new'),
      'CustomerModule',
      logo,
      3,
    )
  }

  if (zohoIsInValid) {
    return ''
  }

  if (!dataCollectionAuth) {
    return (
      <PanelNew
        logo={logo}
        alt={t('subscriptionForm:customer_module.module_title_new')}
        title={t('subscriptionForm:customer_module.module_title_new')}
        id='CustomerModule'
      >
        <Disclaimer
          disabled={isEditable === false}
          checked={dataCollectionAuth}
          errorText=''
          mandatory={isMandatory('dataCollectionAuth', mandatoryFields)}
          setChecked={authorizeDataCollectionHandler}
        />
      </PanelNew>
    )
  }

  return (
    <PanelNew
      logo={logo}
      alt={t('subscriptionForm:customer_module.module_title_new')}
      title={t('subscriptionForm:customer_module.module_title_new')}
      id='CustomerModule'
    >
      <Disclaimer
        disabled={isEditable === false}
        checked={dataCollectionAuth}
        mandatory={isMandatory('dataCollectionAuth', mandatoryFields)}
        setChecked={authorizeDataCollectionHandler}
      />
      <ClientType
        clientTypeOptions={clientTypeOptions}
        clientType={clientType}
        isEditable={isEditable}
        updateClientTypeHandler={value => customerUpdate({ clientType: value })}
        mandatory={isMandatory('contractType', mandatoryFields)}
        errorText={getError('contract_type', errors)}
        label={t('subscriptionForm:customer_module.client_type.label')}
      />
      {clientType === IS_PROFESSIONAL && (
        <>
          {config.siret && (
            <ProSiret
              disabled={isEditable === false}
              currentValue={proSiret ?? ''}
              onChangeHandler={value => customerUpdate({ pro_siret: value })}
              removeErrors={removeErrors}
              siretHandler={{
                handler: verifySiret,
                loader: siretIsLoading,
              }}
              mandatory={isMandatory('siret', mandatoryFields)}
              error={siretError}
            />
          )}
          <FormControl
            label={
              t('subscriptionForm:customer_module.proSocialReason.label') + isMandatory('socialReason', mandatoryFields)
            }
            error={getError('socialReason', errors)}
            caption={t('subscriptionForm:customer_module.proSocialReason.caption')}
          >
            <HOPInput
              disabled={isEditable === false}
              handleValue={value =>
                customerUpdate({
                  pro_social_reason: value,
                })
              }
              currentValue={proSocialReason ?? ''}
            />
          </FormControl>
          {config.apeCode && (
            <FormControl
              label={t('subscriptionForm:customer_module.proCodeApe.label') + isMandatory('apeCode', mandatoryFields)}
              error={getError('codeApe', errors)}
            >
              <HOPInput
                disabled={isEditable === false}
                handleValue={value => customerUpdate({ pro_ape_code: value })}
                currentValue={proApeCode ?? ''}
              />
            </FormControl>
          )}
          {config.activity && (
            <FormControl
              label={t('subscriptionForm:customer_module.proActivity.label') + isMandatory('activity', mandatoryFields)}
              error={activityError}
            >
              <HOPSelect
                disabled={isEditable === false}
                optionsList={activitiesList}
                onChangeHandler={params => customerUpdate({ pro_activity: params.option?.id ?? '' })}
                placeholder={t('subscriptionForm:customer_module.proActivity.placeholder')}
                selectedValue={proActivity ? proActivity.toString() : ''}
                hasError={activityError !== ''}
              />
            </FormControl>
          )}
          <ParagraphMedium>{t('subscriptionForm:customer_module.proContact')}</ParagraphMedium>
        </>
      )}
      <FormControl
        label={t('subscriptionForm:customer_module.civility.label') + isMandatory('civility', mandatoryFields)}
        error={getError('civility', errors)}
      >
        <HOPButtonGroup
          disabled={isEditable === false}
          onClickHandler={value => customerUpdate({ civility: value })}
          options={optionsToArray(civilityList ?? {})}
          selectedValue={civility}
        />
      </FormControl>

      <FormControl
        label={
          isMandatory('surname', mandatoryFields)
            ? t('subscriptionForm:customer_module.lastname.label') + isMandatory('surname', mandatoryFields)
            : `${t('subscriptionForm:customer_module.lastname.label')} ${t(
                'subscriptionForm:customer_module.lastname.optional',
              )}`
        }
        error={getError('lastname', errors)}
      >
        <HOPInput
          id='input-customer-lastname'
          disabled={isEditable === false}
          handleValue={value => customerUpdate({ surname: value })}
          onBlur={value => customerUpdate({ pdlEligibilityName: value })}
          currentValue={surname ?? ''}
        />
      </FormControl>
      <FormControl
        label={t('subscriptionForm:customer_module.firstname.label') + isMandatory('name', mandatoryFields)}
        error={getError('firstname', errors)}
      >
        <HOPInput
          id='input-customer-firstname'
          disabled={isEditable === false}
          handleValue={value => customerUpdate({ name: value })}
          currentValue={name ?? ''}
        />
      </FormControl>

      {/* to be defined the way we put it mandatory or not <EnergyBirthday customerUpdate={customerUpdate} t={t} /> */}

      <Email
        caption={prefilledEmail.status ? prefilledEmail.message : ''}
        disabled={isEditable === false}
        captionForceEmail={captionForceEmail}
        currentValue={email ?? ''}
        error={emailError}
        isLoading={emailIsLoading}
        mandatory={isMandatory('email', mandatoryFields)}
        onChangeHandler={value => customerUpdate({ email: value })}
        updateNeverbounce={value => customerUpdate({ neverbounce_result: value })}
        removeErrors={removeErrors}
        verifyEmail={verifyEmail}
      />

      <NewsletterBonsPlans disabled={isEditable === false} isNewsletterRegistered={isNewsletterRegistered} />

      <FormControl
        label={t('subscriptionForm:customer_module.phone1.label') + isMandatory('phone1', mandatoryFields)}
        error={getError('phone1', errors)}
      >
        <HOPInput
          id='input-customer-phone1'
          disabled={isEditable === false}
          handleValue={value => customerUpdate({ phone1: value })}
          currentValue={phone1 ?? ''}
        />
      </FormControl>

      <FormControl
        label={t('subscriptionForm:customer_module.phone2.label') + isMandatory('phone2', mandatoryFields)}
        error={getError('phone2', errors)}
      >
        <HOPInput
          disabled={isEditable === false}
          handleValue={value => customerUpdate({ phone2: value })}
          currentValue={phone2 ?? ''}
        />
      </FormControl>

      <SecondName
        currentValue={secondName}
        onClickHandler={value => customerUpdate({ secondName: value })}
        disabled={isEditable === false}
      >
        <FormControl
          label={t('subscriptionForm:customer_module.civility2.label') + isMandatory('civility2', mandatoryFields)}
          error={getError('civility2', errors)}
        >
          <HOPButtonGroup
            disabled={isEditable === false}
            onClickHandler={value => customerUpdate({ civility2: value })}
            options={optionsToArray(civilityList ?? {})}
            selectedValue={civility2}
          />
        </FormControl>

        <FormControl
          label={t('subscriptionForm:customer_module.lastname2.label') + isMandatory('surname2', mandatoryFields)}
          error={getError('lastname', errors)}
        >
          <HOPInput
            disabled={isEditable === false}
            handleValue={value => customerUpdate({ surname2: value })}
            currentValue={surname2 ?? ''}
          />
        </FormControl>

        <FormControl
          label={t('subscriptionForm:customer_module.firstname2.label') + isMandatory('name2', mandatoryFields)}
          error={getError('firstname2', errors)}
        >
          <HOPInput
            disabled={isEditable === false}
            handleValue={value => customerUpdate({ name2: value })}
            currentValue={name2 ?? ''}
          />
        </FormControl>
      </SecondName>
      {config.bacVoucher && (
        <BacVoucher
          disabled={isEditable === false}
          handleValue={value => customerUpdate({ bacVoucher: value })}
          currentValue={bacVoucher ?? false}
        />
      )}
      {config.referral && (
        <ReferralContact
          disabled={isEditable === false}
          onChangeReferralFirstName={value => customerUpdate({ referralFirstName: value })}
          onChangeReferralSecondName={value => customerUpdate({ referralSecondName: value })}
          firstNamecurrentValue={referralFirstName ?? ''}
          secondNamecurrentValue={referralSecondName ?? ''}
        />
      )}
      {config.taxId && (
        <FormControl
          label={t('subscriptionForm:customer_module.taxIdCode.label') + isMandatory('taxIdCode', mandatoryFields)}
          error={getError('taxIdCode', errors)}
        >
          <HOPInput
            id='input-customer-tax-id-code'
            disabled={isEditable === false}
            handleValue={value => customerUpdate({ tax_id_code: value })}
            currentValue={taxIdCode ?? ''}
          />
        </FormControl>
      )}
    </PanelNew>
  )
}

export default Customer
